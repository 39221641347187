import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import backHome from "../../Assets/images/bgp.jpg";
import icon from "../../Assets/images/check.png";
import { ImCross } from "react-icons/im";
import AppointmentModal from "./AppintmentModal";
import axios from "axios";

const PLanSection = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [pakageDetails, setPakageDetails] = useState({
    lookUp: "",
    priceId: "",
  });
  const [formData, seFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
  });
  const handleChange = (key, value) => {
    seFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const data = [
    {
      day: "1+ Night",
      price: "$ 275 /night",
      list: [" 5.8% Tax Included "],
      priceId: "price_1PLsJTP7W36gCGc27qBlkmyy",
    },
    {
      day: "7+ Night",
      look: "7nights",
      price: "$ 220 /night",
      list: ["20% Discount", " 5.8% Tax Included "],
      priceId: "price_1PLsHzP7W36gCGc2hzkW9TfM",
    },
    {
      day: "30+ Night",
      look: "30nights",
      price: "$ 165 /night",
      list: ["40% Discount", " 5.8% Tax Included "],
      priceId: "price_1PLsL1P7W36gCGc2U5gurXjd",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://tcc-server-virid.vercel.app/icalendar"
        );
        const eventsData = response.data.map((event) => ({
          title: event.summary,
          start: new Date(event.dtstart),
          end: new Date(event.dtend),
          description: event.description,
        }));
        setEvents(eventsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box sx={style.main}>
        <Container sx={style.container}>
          <Grid
            container
            spacing={5}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {data.map((item, index) => {
              return (
                <Grid item lg={4} sm={6} xs={12} key={index}>
                  <Box sx={style.cardBox}>
                    <Box sx={style.cardTopBox}>
                      <Typography sx={style.days}>{item.day}</Typography>
                      <Typography sx={style.price}>{item.price}</Typography>
                    </Box>
                    <Box sx={style.listingBox}>
                      {item.list.map((list, indexa) => {
                        return (
                          <>
                            <Box sx={style.listItem} key={indexa}>
                              <img src={icon} alt="oops" />
                              <Typography sx={style.item}>{list}</Typography>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                    <Button
                      sx={style.btn}
                      onClick={() => {
                        setPakageDetails((prev) => ({
                          ...prev,
                          lookUp: item.look,
                          priceId: item.priceId,
                          name: item.day,
                        }));
                        handleClickOpen();
                      }}
                    >
                      Purchase Now
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {open && (
          <AppointmentModal
            open={open}
            handleClose={handleClose}
            seFormData={seFormData}
            formData={formData}
            handleChange={handleChange}
            pakageDetails={pakageDetails}
            events={events}
            setEvents={setEvents}
            loading={loading}
          />
        )}
      </Box>
    </>
  );
};

export default PLanSection;
const style = {
  main: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backHome})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingY: "5rem",
  },
  container: {
    maxWidth: { lg: "1400px" },
  },
  cardBox: {
    // width: "100%",
    paddingX: "2.5rem",
    paddingY: "3.5rem",
    backgroundColor: "white",
    color: "black",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transition: "all ease-out 0.5s",
    position: "relative",
    height: "365px",
    ":hover": {
      transform: "scale(1.1)",
      backgroundColor: "#0E71AB",
      zIndex: 99,
      color: "white",
    },
  },
  cardTopBox: {
    width: "fit-content",
    borderBottom: "3px dotted black",
  },
  days: {
    fontSize: "45px",
    color: "inherit",
    opacity: 0.8,
    textAlign: "center",
    fontWeight: 800,
    fontFamily: "Poppins",
    paddingBottom: "10px",
  },
  price: {
    fontSize: "30px",
    color: "inherit",
    opacity: 0.8,
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "Poppins",
    paddingBottom: "1rem",
  },
  listingBox: {
    paddingY: "2rem",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "14px",
    gap: "12px",
  },
  item: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 18,
    color: "inherit",
    opacity: 0.8,
  },
  btn: {
    fontFamily: "Poppins",
    backgroundColor: "#006AA6",
    color: "white",
    paddingY: "14px",
    borderRadius: "30px",
    width: "100%",
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 600,
    ":hover": {
      backgroundColor: "#3BAAE0",
    },
  },
};
